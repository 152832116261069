import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import MainLayout from '../../layout/MainLayout';
import './style.css';
const data = {
  lois: [
    {
      title: 'LOI N°06/PR/2015 PORTANT CRÉATION DE L’ANSICE TÉLÉCHARGER',
      link: "../../assets/documents/loi_06.pdf",
    },
    {
      title:
        'LOI N°07/PR/2015 PORTANT PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL',
      link: '../../assets/documents/loi_07.pdf',
    },
    {
      title: 'LOI N°08/PR/2015 PORTANT SUR LES TRANSACTIONS ÉLECTRONIQUES',
      link: '../../assets/documents/loi_08.pdf',
    },
    {
      title:
        'LOI N°09/PR/2015 PORTANT SUR LA CYBERSÉCURITÉ ET LA LUTTE CONTRE LA CYBERCRIMINALITÉ',
      link: '../../assets/documents/loi_09.pdf',
    },
  ],
  ordonnance: [
    {
      title: 'ORDONNANCE N°08/PCMT/2022 PORTANT SUR LA CYBERSÉCURITÉ AU TCHAD',
      link: '../../assets/documents/ordonnance_008.pdf',
    },
    {
      title:
        'ORDONNANCE N°009/PCMT/2022 PORTANT MODIFICATION DE LA LOI N°006/PR/2015 PORTANT CRÉATION DE L’AGENCE NATIONALE DE SÉCURITÉ INFORMATIQUE ET DE LA CERTIFICATION ÉLECTRONIQUE (ANSICE)',
      link: '../../assets/documents/ordonnance_009.pdf',
    },
  ],
  decrets: [
    {
      title:
        'DÉCRET N°074 FIXANT LES MODALITÉS DE GESTION DU FONDS SPÉCIAL DES ACTIVITÉS DE CYBERSÉCURITÉ',
      link: '../../assets/documents/DECRET_074.pdf',
    },
    {
      title:
        "DÉCRET N°075/PR/2019 FIXANT LES DISPOSITIONS D'APPLICATION DE LA LOI N° 007/PR/2015 DU 10 FÉVRIER 2015 PORTANT SUR LA PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL",
      link: '../../assets/documents/DECRET_075.pdf',
    },
    {
      title:
        "DÉCRET N°076/PR/2019 FIXANT LES CONDITIONS ET LES MODALITÉS D'AUDIT DE SÉCURITÉ OBLIGATOIRE DES RÉSEAUX DE COMMUNICATIONS ÉLECTRONIQUES ET DES SYSTÈMES D'INFORMATION",
      link: '../../assets/documents/DECRET_076.pdf',
    },
    {
      title:
        "DÉCRET N°077/PR/2019 PORTANT RÈGLES PARTICULIÈRES RÉGISSANT LES AGENTS DE L'AGENCE NATIONALE DE SÉCURITÉ INFORMATIQUE ET DE CERTIFICATION ÉLECTRONIQUE (ANSICE)",
      link: '../../assets/documents/DECRET_077.pdf',
    },
    {
      title:
        'DÉCRET N°078/PR/2019 FIXANT LES MODALITÉS DE FOURNITURE DES PRESTATIONS ET MOYENS DE CRYPTOLOGIE',
      link: '../../assets/documents/DECRET_078.pdf',
    },
    {
      title:
        "DÉCRET N°079/PR/2019 FIXANT LES CONDITIONS ET LES MODALITÉS D'OCTROI DE L'AUTORISATION D'EXERCICE DE L'ACTIVITÉ DE CERTIFICATION ÉLECTRONIQUE",
      link: '../../assets/documents/DECRET_079.pdf',
    },
    {
      title:
        "DÉCRET N°1619/PR/2019 PORTANT RECTIFICATION DES DISPOSITIONS DE L'ARTICLE 5 DU DÉCRET N°075/PR/2019 DU 21 JANVIER 2019 PORTANT SUR LA PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL",
      link: '../../assets/documents/DECRET_1619.pdf',
    },
  ],
  convention: [],
  decision: [],
};

const FeatureFive = () => {
  useEffect(() => {
    const $ = window.$;

    if ($('.tabs-box').length) {
      $('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
        e.preventDefault();
        const target = $($(this).attr('data-tab'));

        if ($(target).is(':visible')) {
          return false;
        } else {
          target
            .parents('.tabs-box')
            .find('.tab-buttons')
            .find('.tab-btn')
            .removeClass('active-btn');
          $(this).addClass('active-btn');
          target
            .parents('.tabs-box')
            .find('.tabs-content')
            .find('.tab')
            .fadeOut(0);
          target
            .parents('.tabs-box')
            .find('.tabs-content')
            .find('.tab')
            .removeClass('active-tab');
          $(target).fadeIn(300);
          $(target).addClass('active-tab');
        }
      });
    }
  }, []);

  const renderTabContent = (category) => (
    <Row>
      {data[category].map((item, index) => (
        <Col md={12} key={index} className='m-1'>
          <ListGroup
            as='ul'
            style={{ listStyleType: 'disc', paddingLeft: '20px' }}
          >
            <ListGroup.Item className='list-group-item-custom'>
              <span className='icon-tisck'></span>
              <div className='items-content'>
                <li className='bullet-point'>
                  {item.title}
                  <a
                    href={item.link}
                    className='text-primary'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Télécharger
                    <span className='icon-document'></span>
                  </a>
                </li>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      ))}
    </Row>
  );

  return (
    <MainLayout>
      <section className='feature-three-sec clearfix'>
        <div className='container'>
          <div className='sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='left'></span>
              <h6>Textes</h6> <span className='right'></span>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='feature-three__tab tabs-box'>
                <div className='feature-three__tab-button'>
                  <ul className='tab-buttons clearfix'>
                    <li data-tab='#lois' className='tab-btn active-btn'>
                      <h3>Lois</h3>
                    </li>
                    <li data-tab='#ordonnance' className='tab-btn'>
                      <h3>Ordonnances</h3>
                    </li>
                    <li data-tab='#decrets' className='tab-btn'>
                      <h3>Décrets</h3>
                    </li>
                    <li data-tab='#decision' className='tab-btn'>
                      <h3>Décision</h3>
                    </li>
                    <li data-tab='#convention' className='tab-btn'>
                      <h3>Convention</h3>
                    </li>
                  </ul>
                </div>

                <div className='tabs-content'>
                  <div className='tab active-tab' id='lois'>
                    {renderTabContent('lois')}
                  </div>
                  <div className='tab' id='ordonnance'>
                    {renderTabContent('ordonnance')}
                  </div>
                  <div className='tab' id='decrets'>
                    {renderTabContent('decrets')}
                  </div>
                  <div className='tab' id='decision'>
                    {renderTabContent('decision')}
                  </div>
                  <div className='tab' id='convention'>
                    {renderTabContent('convention')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default FeatureFive;
